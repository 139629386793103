import type { NuxtConfig } from 'nuxt/config'
import { useLanguage } from '~/composables/useLanguage'
import type { LangData } from '~/type/config'

export default defineI18nConfig(() => {
  const {
    public: { VITE_DEFAULT_LANG }
  } = useRuntimeConfig()

  const { language } = useLanguage()
  const lang: LangData = {
    en: {},
    vi: {},
    zh: {}
  }

  return {
    fallbackLocale: VITE_DEFAULT_LANG,
    locale: language.value,
    messages: lang as any
  }
}) as NuxtConfig
